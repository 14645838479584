import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { param } from 'jquery';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContainerService {

  apiPrefix: string;
  headers = new HttpHeaders();
  public selectedInspectorSubject: BehaviorSubject<any>;
  public selectedInspector = new Observable<any>();

  constructor(
    private http: HttpClient
  ) {
    this.apiPrefix = environment.baseUrl + 'api/v1/container/';
    this.headers = this.headers.set('x-access-token', localStorage.getItem("adventouresAdminAuthToken"));
  }

  getContainerList(params) {
    return this.http.post(this.apiPrefix + 'get', params, { headers: this.headers });
  }

  createContainer(params) {
    return this.http.post(this.apiPrefix + 'create', params, { headers: this.headers });
  }

  getContainerInspection(params) {
    return this.http.post(environment.baseUrl + 'api/v1/container-inspection/get', params, { headers: this.headers });
  }

  updateContainerInspection(params) {
    return this.http.post(environment.baseUrl + 'api/v1/container-inspection/create', params, { headers: this.headers });
  }
}
