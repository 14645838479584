import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nameFilter'
})
export class NameFilterPipe implements PipeTransform {

    transform(items: any, term: any): any {
        if (term) {
            return items.filter(item => {
                return Object.keys(item).some(
                    k => {
                        if (item[k] == undefined || item[k] == null) {
                            item[k] = "";
                        }
                        let newItem = item[k].toString();
                        if (newItem != null && newItem != undefined) {
                            return newItem.toLowerCase().includes(term.toLowerCase());
                        }
                    }
                );
            });
        }
        return items;
    }

}
