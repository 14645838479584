<div class="wrapper mt-4">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="form-group mt-4">
                    <label for="description">Add text:</label>
                    <quill-editor [styles]="{height: '200px'}" [modules]="quillConfig" [(ngModel)]="editorText"
                        [preserveWhitespace]="false" name="description">
                    </quill-editor>
                </div>
                <div class="text-center mt-4">
                    <button type="submit"
                        style="background-color: #888979; width: 200px; border-radius: 10px; color: white; border: none; padding: 5px;"
                        (click)="onSubmit()">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>