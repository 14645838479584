import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiPrefix: any;
  headers = new HttpHeaders();

  //Behaviour subject for storing authentication state
  loggedIn = new BehaviorSubject<boolean>(false);
  permissions = new BehaviorSubject<[]>([]);

  //Behaviour subject for storing the user
  authUser = new BehaviorSubject<Object>(null);

  //Behaviour subject for storing the user
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient, private routerBtn: Router) {
    this.apiPrefix = environment.baseUrl + 'api/v1/';

    this.headers = this.headers.set(
      'x-access-token',
      localStorage.getItem('adventouresAdminAuthToken')
    );


    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('authAdventurousUserData'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get currentUserPermissions(): any {
    const permissions = [];
    for (const [key, value] of Object.entries(this.currentUserValue)) {
      if (typeof value === 'boolean' && value) {
        permissions.push(key);
      }
    }
    return permissions;
  }

  private listeners = new Subject<any>();
  listen(): Observable<any> {
    return this.listeners.asObservable();
  }

  filter(filterBy) {
    this.listeners.next(filterBy);
  }

  //method to send signin request to webapi
  loginUser(data) {
    return this.http.post(this.apiPrefix + 'admin/login', data);
  }

  loginSubAdmin(data) {
    return this.http.post(this.apiPrefix + 'login-sub-admin', data);
  }

  //method to implement auto-login functionality
  autoLogin() {
    //now we will retrieve all data from local storage , whenever the application restarts
    const authUserInfo = localStorage.getItem('authAdventurousUserData');
    //checking if that data key exists
    if (!authUserInfo) {
      return;
    } else {
      //emitting login details to BehaviourSubject
      this.loggedIn.next(true);
      this.authUser.next(JSON.parse(authUserInfo));
    }
  }

  //method to implement LOGOUT  functionality
  logout() {
    //now emitting no user  (setting our User to null)

    this.loggedIn.next(false);
    this.authUser.next(null);

    //also, removing user data from localStorage
    localStorage.removeItem('adventouresAdminAuthToken');
    localStorage.removeItem('authAdventurousUserData');
    localStorage.removeItem('permissions');
    localStorage.removeItem('userType');

    //redirecting to different component
    this.routerBtn.navigate(['/admin/login']);
  }

  sendToken(params) {
    return this.http.post(this.apiPrefix + 'admin-send-token', params);
  }

  adminForgotPwd(params) {
    return this.http.post(this.apiPrefix + 'admin-forgot-password', params);
  }

  adminDetails(params) {
    return this.http.post(this.apiPrefix + 'admin/profile', params, {
      headers: this.headers,
    });
  }

  subAdminDetails(id) {
    return this.http.get(this.apiPrefix + 'all-sub-admins/' + id);
  }

  editAdminDetails(params) {
    return this.http.post(this.apiPrefix + 'admin/update', params, {
      headers: this.headers,
    });
  }

  editAdminPassword(params) {
    return this.http.post(this.apiPrefix + 'admin/update-password', params, {
      headers: this.headers,
    });
  }

  editAdminImage(params) {
    return this.http.post(this.apiPrefix + 'admin/update', params, {
      headers: this.headers,
    });
  }

  public readFile(url) {
    let headers = new HttpHeaders();
    headers = headers.set(
      'x-access-token',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2VtYWlsIjoidGVzdEBnbWFpbC5jb20iLCJpYXQiOjE2NjI1Njc0OTF9.mNZ5Mh-KVbd_z2ieoesgkRHYvRykdQkuW4bPc_ZaYiA'
    );
    return this.http
      .get(environment.imageUrlPrefix + url, {
        headers: headers,
        responseType: 'blob',
      })
      .pipe(switchMap((blob) => this.convertBlobToBase64(blob)));
  }

  convertBlobToBase64(blob: Blob) {
    return Observable.create((observer: any) => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
        observer.next(event.target.result);
        observer.complete();
      };

      reader.onerror = (event: any) => {
        console.log('File could not be read: ' + event.target.error.code);
        observer.next(event.target.error.code);
        observer.complete();
      };
    });
  }
  public getFileBlob(url) {
    let headers = new HttpHeaders();
    headers = headers.set(
      'x-access-token',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2VtYWlsIjoidGVzdEBnbWFpbC5jb20iLCJpYXQiOjE2NjI1Njc0OTF9.mNZ5Mh-KVbd_z2ieoesgkRHYvRykdQkuW4bPc_ZaYiA'
    );
    return this.http.get(environment.imageUrlPrefix + url, {
      headers: headers,
      responseType: 'blob',
    });
  }
  //Sub admin APIs
  getAllSubAdmins() {
    return this.http.post(this.apiPrefix + 'admin/get-admins', {}, {
      headers: this.headers,
    });
  }

  createSubAdmin(params) {
    return this.http.post(`${this.apiPrefix}/admin/create`, params, {
      headers: this.headers,
    });
  }

  editSubAdmin(params) {
    return this.http.post(`${this.apiPrefix}/admin/update-permission`, params, {
      headers: this.headers,
    });
  }

  deleteSubAdmin(params) {
    return this.http.post(this.apiPrefix + 'admin/delete', params, {
      headers: this.headers,
    });
  }
}