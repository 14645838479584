import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { InspectionService } from 'src/app/services/inspection.service';

$(function () {
  var $select = $("#selectContainer");
  for (let i: any = 1; i <= 100; i++) {
    $select.append($('<option></option>').val(i).html(i))
  }
});

@Component({
  selector: 'app-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.css']
})
export class SaveComponent implements OnInit, AfterViewInit {

  totalContainers;
  bookingForm: FormGroup;
  formSubmitted = false;
  inspectorEmail: string;
  today: any = new Date();

  constructor(
    private fb: FormBuilder,
    private inspectionService: InspectionService,
    private router: Router,
    private toast: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.bookingForm = this.fb.group({
      bookingNumber: ['', Validators.required],
      containers: ['', Validators.required],
      inspectionDate: ['', Validators.required],
      containerData: this.fb.array([])
    });
  }

  ngOnInit(): void {
    $(function () {
      var $select = $("#selectContainer");
      for (let i: any = 1; i <= 100; i++) {
        $select.append($('<option></option>').val(i).html(i))
      }
    });

    this.inspectorEmail = JSON.parse(localStorage.getItem('selectedInspector')).user_email;
  }

  ngAfterViewInit(): void {
    var dd = String(this.today.getDate()).padStart(2, '0');
    var mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = this.today.getFullYear();

    this.today = yyyy + '-' + mm + '-' + dd;

    // document.getElementById('inspectionDate').setAttribute('min',  yyyy + '-' + mm + '-' + dd);
  }

  getToday() {
    var dd = String(this.today.getDate()).padStart(2, '0');
    var mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = this.today.getFullYear();

    return yyyy + '/' + mm + '/' + dd;
  }

  get containerData(): FormArray {
    return this.bookingForm.get('containerData') as FormArray
  }

  newContainer(): FormGroup {
    return this.fb.group({
      containerNumber: ['', Validators.required],
      inspectionDate: ['', Validators.required]
    })
  }

  addContainer() {
    this.containerData.push(this.newContainer());
  }

  removeContainer(i: number) {
    this.containerData.removeAt(i);
    this.bookingForm.get('containers').setValue(this.containerData.length);
  }

  onSubmit() {
    this.spinner.show();
    this.formSubmitted = true;
    if (!this.bookingForm.valid) {
      this.spinner.hide();
      console.log('Invalid');
      return;
    } else {
      this.bookingForm.value.assigned_to = this.inspectorEmail;
      this.inspectionService.createInspection(this.bookingForm.value).subscribe((res: any) => {
        if (res.status) {
          this.spinner.hide();
          this.router.navigate(['/admin/bookings'])
          this.toast.success(res.message);
        }
      }, error => {
        console.log(error)
        this.spinner.hide();
        this.toast.error(error.error.description);
      })
    }
  }

  onContainerChange(e) {
    if (e.target.value > 0) {
      while (this.containerData.length !== 0) {
        this.containerData.removeAt(0)
      }

      for (let i = 1; i <= e.target.value; i++) {
        this.addContainer();
      }
    }
  }

}
