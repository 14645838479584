import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectorService {

  apiPrefix: string;
  headers = new HttpHeaders();

  constructor(
    private http: HttpClient
  ) {
    this.apiPrefix = environment.baseUrl + 'api/v1/app/';
    this.headers = this.headers.set('x-access-token', localStorage.getItem("adventouresAdminAuthToken"));
  }

  getAllInspectors(params) {    
    return this.http.post(this.apiPrefix + 'get-all-user-profile', params, {headers: this.headers });
  }

  updateStatus(params) {
    return this.http.post(this.apiPrefix + 'update-status', params, {headers: this.headers });
  }
}
