<div class="container">
  <hr />
  <form [formGroup]="containerForm" (ngSubmit)="onSubmit()">
    <div class="row mt-4">
      <div class="col-md-5">
        <label> Container Number: </label>
      </div>
      <div class="col-md-7">
        <input
          type="text"
          class="form-control"
          formControlName="containerNumber"
          placeholder="Enter container number"
        />
        <div
          *ngIf="
            (formSubmitted || containerForm.controls.containerNumber.touched) &&
            containerForm.controls.containerNumber.errors?.required
          "
          class="error"
        >
          *Container number is required!
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-5">
        <label> Date Of Inspection: </label>
      </div>
      <div class="col-md-7">
        <input
          type="date"
          class="form-control"
          formControlName="inspectionDate"
        />
        <div
          *ngIf="
            (formSubmitted || containerForm.controls.inspectionDate.touched) &&
            containerForm.controls.inspectionDate.errors?.required
          "
          class="error"
        >
          *Date of inspection is required!
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12 text-center">
        <button
          type="submit"
          [disabled]="!containerForm.valid"
          style="background-color: #0065ff"
          class="btn btn-block text-white"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
  <hr />
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Submitting...</p>
</ngx-spinner>
