<div *ngIf="isLoggedIn">
  <div class="d-flex" id="wrapper">
    <!-- Sidebar -->
    <div style="border-right: 2px solid #d3d3d3;" id="sidebar-wrapper">
      <div class="sidebar-heading text-center my-lg-1">
        <img src="../assets/loginLogo.png" width="100px" height="105px" alt="">
        <br>
        The Adventurous Learner
      </div>
      <div class="list-group list-group-flush mt-lg-2 mySideBar" style="overflow-y: auto;">
        <a routerLink="/admin/dashboard" routerLinkActive='selected' style="
        font-size: 17px;
        width: 100%;
        padding:13px;" class="pl-lg-4" *ngIf="adminDetails?.dashboard">
          <i class="fas fa-th-large"></i>
          &nbsp; Dashboard
        </a>

        <a routerLink="/admin/customers" routerLinkActive='selected' style="font-size: 17px;width: 100%;padding:13px;"
          class="pl-lg-4" *ngIf="adminDetails?.userData"><i class="fas fa-users"></i> &nbsp;Customers</a>

        <a routerLink="/admin/locations/pending" style="font-size: 17px;width: 100%;padding:13px;" class="pl-lg-4" *ngIf="adminDetails?.locations"><i
            class="fas fa-map-marker-alt"></i> &nbsp;Locations</a>
        <ul class="submenu" *ngIf="adminDetails?.locations">
          <li style="font-size: 17px;width: 100%;padding:13px;" routerLink="/admin/locations/pending"
            routerLinkActive='selected'><a class="pl-lg-4 pending" *ngIf="adminDetails?.locations"><i class="far fa-clock"></i>&nbsp;Pending</a>
          </li>
          <li style="font-size: 17px;width: 100%;padding:13px;" routerLink="/admin/locations/approved"
            routerLinkActive='selected'><a class="pl-lg-4 approved" *ngIf="adminDetails?.locations"><i class="fas fa-check"></i>&nbsp;Approved</a>
          </li>
          <li style="font-size: 17px;width: 100%;padding:13px;" routerLink="/admin/locations/rejected"
            routerLinkActive='selected'><a class="pl-lg-4 rejected" *ngIf="adminDetails?.locations"><i class="fas fa-times"></i>&nbsp;Rejected</a>
          </li>
        </ul>

        <a routerLink="/admin/resources" routerLinkActive='selected' style="font-size: 17px;width: 100%;padding:13px;"
          class="pl-lg-4" *ngIf="adminDetails?.resources"><i class="fas fa-chart-pie"></i> &nbsp;Resources</a>

        <a routerLink="/admin/send-notifications" routerLinkActive='selected'
          style="font-size: 17px;width: 100%;padding:13px;" class="pl-lg-4 " *ngIf="adminDetails?.notification">
          <i class="fas fa-bell"></i> &nbsp;Notifications</a>

          <a routerLink="/admin/sub-admin" routerLinkActive='selected' style="font-size: 17px;width: 100%;padding:13px;"
          class="pl-lg-4" *ngIf="adminDetails?.isSuperAdmin"><i class="fas fa-user"></i> &nbsp;Sub admin</a>

        <a routerLink="/admin/contact-us" routerLinkActive='selected' style="font-size: 17px;width: 100%;padding:13px;"
          class="pl-lg-4 " *ngIf="adminDetails?.contactUs">
          <i class="fas fa-id-badge"></i> &nbsp;Contact Us</a>

        <a routerLink="/admin/home-content" routerLinkActive='selected'
          style="font-size: 17px;width: 100%;padding:13px;" class="pl-lg-4 " *ngIf="adminDetails?.homeContent">
          <i class="fas fa-id-badge"></i> &nbsp;Home Content</a>

        <a routerLink="/admin/blogs" routerLinkActive='selected' style="font-size: 17px;width: 100%;padding:13px;"
          class="pl-lg-4 " *ngIf="adminDetails?.blogs">
          <i class="fab fa-blogger"></i> &nbsp;Blogs</a>

      </div>
    </div>
    <!-- /#sidebar-wrapper -->
    <!-- Page Content -->
    <div id="page-content-wrapper">
      <nav class="navbar navbar-expand-lg navbar-light bg-light1" style="border-bottom: 2px solid lightgray;">

        <div class="text-center pl-lg-5 py-lg-2">
          <!-- <h2 class="myText">Admin Panel</h2> -->
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="toggleNavbar = !toggleNavbar"
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
            <li class="nav-item active">
              <!-- <a class="nav-link" style="font-weight: 600;" routerLink="/admin/dashboard">Home <span class="sr-only">(current)</span></a> -->
            </li>

            <li class="nav-item dropdown">
              <a *ngIf="adminId!=undefined" style="font-weight:600;color:black;" class="nav-link dropdown-toggle"
                href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                {{name | titlecase}} <img [src]="userImage" onerror="this.src='../assets/welcomeProfile.jpg'"
                  style="border-radius:10px" height="30px" width="36px">
              </a>

              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" style="font-weight: 600;color: black;" routerLink="/admin/profile"><i
                    class="fa fa-user-circle" aria-hidden="true"></i> &nbsp; Profile </a>
                <div class="dropdown-divider"></div>
                <a (click)="logout()" class="dropdown-item" href="#" style="font-weight: 600; color: black;"><i
                    class="fa fa-info-circle" aria-hidden="true"></i> &nbsp; Logout</a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div class="container-fluid">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <router-outlet></router-outlet>

              <!-- <canvas baseChart 
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType">
      </canvas> -->
            </div>
          </div>

        </div>

      </div>
    </div>
    <!-- /#page-content-wrapper -->
  </div>
</div>
<div *ngIf="!isLoggedIn">
  <app-login-user></app-login-user>
</div>