import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-dashboard-data',
  templateUrl: './dashboard-data.component.html',
  styleUrls: ['./dashboard-data.component.css']
})
export class DashboardDataComponent implements OnInit {

  totalUsers: number = 0;
  totalLocations: number = 0;
  totalSuggestedLocations: number = 0;

  // public barChartOptions: ChartOptions = {
  //   responsive: true,
  // };
  // public barChartLabels = ['2015', '2016', '2017', '2018', '2019', '2020'];
  // public barChartType: ChartType = 'bar';
  // public barChartLegend = true;
  // public barChartPlugins = [];

  // public barChartData: ChartDataSets[] = [
  //   { data: [65, 67, 70, 75, 80, 90], label: 'PHP' },
  //   { data: [50, 48, 47, 49, 44, 40], label: '.Net' },
  //   { data: [40, 30, 28, 25, 22, 20], label: 'Java' },
  // ];

  //bar chart (for users)
  // barChartOptions: ChartOptions = {
  //   responsive: true,
  //   scales: {
  //     yAxes: [{
  //         gridLines: {
  //             display:true
  //         },
  //         display: true,
  //         ticks: {
  //           stepSize:4,
  //           min:0
  //         }
  //     }]
  //   }
  // };
  // barChartLabels = [];
  // barChartType: ChartType = 'bar';
  // barChartLegend = true;
  // barChartPlugins = [];

  // barChartData: ChartDataSets[] = [
  //   { data: [], label: 'Users Per Month' }
  // ];

  // public barChartColors= [
  //   {
  //     backgroundColor:['rgba(255,0,0,0.3)','rgba(0,255,0,0.3)','rgba(0,0,255,0.3)','rgba(255,0,0,0.3)','rgba(0,255,0,0.3)','rgba(0,0,255,0.3)']
  //   }
  // ];

  constructor(
    private userService: UserService,
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.dashboardService.getDashboardSummary({}).subscribe((res: any) => {
      this.spinner.hide();
      this.totalUsers = res.totalUsers;
      this.totalSuggestedLocations = res.suggestedLocation;
      this.totalLocations = res.locationData;
    });

    // this.dashboardService.getUserGraph({}).subscribe(graphRes=>{
    //   console.log(graphRes);
    //   graphRes["data"].forEach(singleGraph=>{
    //     this.barChartLabels.push(singleGraph.month);
    //     this.barChartData[0].data.push(singleGraph.data);
    //   });
    // });  
  }

}
