<div class="wrapper mt-4">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="file-upload-container mt-4" (click)="onUploadClick()" *ngIf="!selectedPhoto">
                    <i class="fas fa-upload"></i>
                    <span class="ml-2">Upload photo here</span>
                    <input id="file-input" type="file" (change)="onSelect($event)" hidden #fileUploader />
                </div>
                <div class="col file-upload-selected" *ngIf="selectedPhoto">
                    {{selectedPhoto.name}}
                </div>
                <div class="form-group mt-4">
                    <label for="description">Add text:</label>
                    <textarea rows="4" class="form-control" id="description"
                        [(ngModel)]="contactDetails.description"></textarea>
                </div>
                <div class="form-group mt-4">
                    <label for="email">Add Email:</label>
                    <input type="text" class="form-control" id="email" [(ngModel)]="contactDetails.email" />
                </div>
                <div class="text-center mt-4">
                    <button type="submit"
                        style="background-color: #888979; width: 200px; border-radius: 10px; color: white; border: none; padding: 5px;"
                        (click)="onSubmit()">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">{{ loadingText }}</p>
</ngx-spinner>