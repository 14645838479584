import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ContainerService } from 'src/app/services/container.service';
import { countries } from 'src/app/countries';


@Component({
  selector: 'app-container-inspection-view',
  templateUrl: './container-inspection-view.component.html',
  styleUrls: ['./container-inspection-view.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ContainerInspectionViewComponent implements OnInit {
  inspectionDetailsForm: FormGroup;
  inspectionDetails: any;
  previewUrl: string;
  previewAddress: string;
  selectedCountryCode: string;
  countries: any = countries;

  constructor(
    private fb: FormBuilder,
    private containerService: ContainerService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private toast: ToastrService
  ) {
    this.inspectionDetailsForm = this.fb.group({
      inspectionDate: ['', Validators.required],
      materialType: ['', Validators.required],
      originCountry: ['', Validators.required],
      loadingPort: ['', Validators.required],
      destinationPort: [''],
      shippingLineName: ['', Validators.required],
      shippingSeal: ['', Validators.required],
      atxSeal: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getContainerInspectionDetails();
  }

  getContainerInspectionDetails() {
    this.spinner.show();
    const params = {
      containerId: localStorage.getItem('containerId'),
    };

    this.containerService.getContainerInspection(params).subscribe(
      (res: any) => {
        if (res.status) {
          if (res.data !== null) {
            res.data.detailedPhotos.forEach((path, index) => {
              this.readFile(path.file_path, 'detailedPhotos', index);
            });

            res.data.containerPhotos.map((path, index) => {
              this.readFile(path.file_path, 'containerPhotos', index);
            });

            res.data.loadedPhotos.map((path, index) => {
              this.readFile(path.file_path, 'loadedPhotos', index);
            });

            res.data.doorPhotos.map((path, index) => {
              this.readFile(path.file_path, 'doorPhotos', index);
            });

            res.data.shippingPhotos.map((path, index) => {
              this.readFile(path.file_path, 'shippingPhotos', index);
            });

            res.data.documentPath = this.readReportFile(res.data.documentPath);
            const inspectionDate = new Date(res.data.inspectionDate)
              .toISOString()
              .split('T')[0];
            res.data.inspectionDate = inspectionDate;
            this.inspectionDetails = res.data;
            this.inspectionDetailsForm.patchValue(res.data);
            this.cdRef.detectChanges();
          }
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  readFile(url, arrayname, index) {
    this.authService.readFile(url).subscribe(
      (res) => {
        this.inspectionDetails[arrayname][index].full_path = res;
      },
      (error) => { }
    );
  }

  readReportFile(url) {
    this.authService.getFileBlob(url).subscribe(
      (res) => {
        this.inspectionDetails.documentPath = res;
        this.cdRef.detectChanges();
      },
      (error) => { }
    );
  }

  setImgPreview(data) {
    this.previewUrl = data.file_path;
    this.previewAddress = data.address;
    this.cdRef.detectChanges();
  }

  getReport() {
    if (this.inspectionDetails.documentPath) {
      var fileURL: any = window.URL.createObjectURL(
        this.inspectionDetails.documentPath
      );

      var a = document.createElement('a');
      a.href = fileURL;
      a.target = '_blank';
      a.download =
        'inspection-report.' +
        this.inspectionDetails?.documentPath.type.substring(
          this.inspectionDetails?.documentPath.type.lastIndexOf('/') + 1
        );
      a.click();
    }
  }

  onSubmit() {
    this.spinner.show();
    if (!this.inspectionDetailsForm.valid) {
      this.spinner.hide();
      console.log('Invalid');
      return;
    } else {
      const payload = this.inspectionDetailsForm.value;
      payload.containerNumber = this.inspectionDetails.containerNumber;
      payload.bookingNumber = this.inspectionDetails.bookingNumber;
      payload.containerId = this.inspectionDetails.containerId;
      payload.editData = true;
      payload.inspectionDate = new Date(payload.inspectionDate).toJSON().slice(0, 10).split('-').reverse().join('-')

      this.containerService.updateContainerInspection(payload).subscribe((res: any) => {
        if (res.status) {
          this.spinner.hide();
          this.toast.success(res.description);
        }
      }, (errors: any) => {
        this.spinner.hide();
        this.toast.error(errors.error.description)
      });
    }
  }
}
