import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  @ViewChild('fileUploader') fileUploader: ElementRef;
  contactDetails: any;
  selectedPhoto: any;
  loadingText = 'Loading detais(s)...';

  constructor(
    private contactService: ContactService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.contactDetails = {
      description: '',
      email: '',
      photo: ''
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.getContact();
  }

  getContact() {
    this.contactService.getContact({}).subscribe((res: any) => {
      this.spinner.hide();
      this.contactDetails = res.data;
    }, (errors: any) => {
      this.spinner.hide();
      this.toast.error(errors.error.description, "Error Occured");
    });
  }

  onUploadClick() {
    this.fileUploader.nativeElement.click();
  }

  onSelect(e) {
    this.selectedPhoto = e.target.files[0];
    this.contactDetails.photo = e.target.files[0];
  }

  onSubmit() {
    this.loadingText = 'Saving detail(s)...';
    this.spinner.show();
    const formData = new FormData();
    for (var key in this.contactDetails) {
      if (key === 'photo' && this.contactDetails[key] === '') {
        delete this.contactDetails[key];
      }
      formData.append(key, this.contactDetails[key]);
    }

    if (this.contactDetails) {
      formData.append('id', this.contactDetails._id);

      this.contactService.updateContact(formData).subscribe((res: any) => {
        if (res.status) {
          this.spinner.hide();
          this.toast.success("Contact details updated successfully", "Success");
          this.loadingText = 'Loading detais(s)...';
        }
      }, (errors: any) => {
        this.loadingText = 'Loading detais(s)...';
        this.spinner.hide();
        this.toast.error(errors.error.description, "Error Ocuured");
      });
    } else {
      this.contactService.createContact(formData).subscribe((res: any) => {
        if (res.status) {
          this.spinner.hide();
          this.toast.success(res.description, "Success");
          this.loadingText = 'Loading detais(s)...';
        }
      }, (errors: any) => {
        this.loadingText = 'Loading detais(s)...';
        this.spinner.hide();
        this.toast.error(errors.error.description, "Error Ocuured");
      });

    }
  }

}
