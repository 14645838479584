import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { DashboardDataComponent } from './components/dashboard-data/dashboard-data.component';
import { HomeContentComponent } from './components/home-content/home-content.component';
import { LoginUserComponent } from './components/login-user/login-user.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SendNotificationsComponent } from './components/send-notifications/send-notifications.component';
import { OnlyLoginGuardService } from './services/only-login-guard/only-login-guard.service';
import { BlogsComponent } from './components/blogs/blogs.component';
import { SaveBlogComponent } from './components/blogs/save-blog/save-blog.component';
import { SubAdminComponent } from './components/sub-admin/sub-admin.component';
import { SaveSubAdminComponent } from './components/sub-admin/save-sub-admin/save-sub-admin.component';
import { AuthGuard } from './services/auth.guard';


const routes: Routes = [
  { path: 'admin/dashboard', component: DashboardDataComponent, canActivate: [AuthGuard], },
  { path: 'admin/customers', loadChildren: () => import(`./components/customers/customer.module`).then(m => m.CustomerModule) },
  { path: 'admin/locations', loadChildren: () => import(`./components/locations/locations.module`).then(m => m.LocationsModule) },
  { path: 'admin/resources', loadChildren: () => import(`./components/resource/resource.module`).then(m => m.ResourceModule) },
  {
    path: 'admin/send-notifications',
    component: SendNotificationsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'notification' },
  },
  {
    path: 'admin/contact-us', component: ContactUsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'contactUs' },
  },
  {
    path: 'admin/home-content', component: HomeContentComponent,
    canActivate: [AuthGuard],
    data: { permission: 'homeContent' },
  },
  {
    path: 'admin/blogs', component: BlogsComponent,
    canActivate: [AuthGuard],
    data: { permission: 'blogs' },
  },
  {
    path: 'admin/sub-admin', component: SubAdminComponent,
    canActivate: [AuthGuard],
    data: { permission: 'isSuperAdmin' },
  },
  {
    path: 'admin/sub-admin/save', component: SaveSubAdminComponent,
    canActivate: [AuthGuard],
    data: { permission: 'isSuperAdmin' },
  },
  {
    path: 'admin/sub-admin/save/:id', component: SaveSubAdminComponent,
    canActivate: [AuthGuard],
    data: { permission: 'isSuperAdmin' },
  },
  { path: 'admin/login', component: LoginUserComponent, canActivate: [OnlyLoginGuardService] },
  { path: 'admin/profile', component: ProfileComponent },
  {
    path: 'admin/save-blog', component: SaveBlogComponent,
    canActivate: [AuthGuard],
    data: { permission: 'blogs' },
  },

  { path: '', redirectTo: '/admin/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
