import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-sub-admin',
  templateUrl: './sub-admin.component.html',
  styleUrls: ['./sub-admin.component.css']
})
export class SubAdminComponent implements OnInit {
  allSubAdmins: any[];
  p: number = 1;
  loaderText = 'Loading sub admin(s)....';
  selectedAdmin;
  currentUser;

  constructor(
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.currentUser = this.auth.currentUserValue;
  }

  ngOnInit(): void {
    this.getAllSubAdmins();
  }

  getAllSubAdmins() {
    this.loaderText = 'Loading sub admin(s)....';
    this.spinner.show();
    this.auth.getAllSubAdmins().subscribe(
      (res: any) => {
        if (res.status) {
          this.spinner.hide();
          this.allSubAdmins = res.adminData;
        }
      },
      (errors: any) => {
        this.spinner.hide();
      }
    );
  }

  createSubAdmin() {
    this.router.navigateByUrl('admin/sub-admin/save');
  }

  editSubAdmin(admin) {
    this.router.navigateByUrl('admin/sub-admin/save/' + admin._id);
  }

  setDeleteAdmin(admin) {
    this.selectedAdmin = admin;
  }

  deleteSubAdmin() {
    this.loaderText = 'Sub admin deleting....';
    this.spinner.show();
    this.auth.deleteSubAdmin({ id: this.selectedAdmin._id }).subscribe(
      (res: any) => {
        if (res.status) {
          this.spinner.hide();
          this.selectedAdmin = null;
          document.getElementById('closeModal').click();
          this.toastr.success(res.description, 'Successs', {
            timeOut: 2500,
            progressBar: true,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
          });
          this.getAllSubAdmins();
        }
      },
      (errors: any) => {
        this.spinner.hide();
      }
    );
  }

}
