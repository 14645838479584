import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-send-notifications',
  templateUrl: './send-notifications.component.html',
  styleUrls: ['./send-notifications.component.css']
})
export class SendNotificationsComponent implements OnInit {

  constructor(
    private notificationsService: NotificationService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm) {
    this.spinner.show();
    const params = {
      title: form.value.title,
      body: form.value.message
    };

    this.notificationsService.sendNotificationsToAllUsers(params).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.toastr.info(res.description, "Success", {
          timeOut: 2500,
          progressBar: true,
          progressAnimation: 'increasing',
          positionClass: 'toast-top-right'
        });
        form.reset();
      } else {
        this.toastr.error(res.description, "Error Occured", {
          timeOut: 2500,
          progressBar: true,
          progressAnimation: 'increasing',
          positionClass: 'toast-top-right'
        })
      }
    }, err => {
      this.spinner.hide();
      this.toastr.error("", "Error Occured", {
        timeOut: 2500,
        progressBar: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-top-right'
      })
    });
  }

}
