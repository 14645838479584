<div class="wrapper" style="margin-top: 0px">
  <div class="table2" style="margin-bottom: 10px">
    <div class="row2">
      <div class="cell2 text-left" style="box-shadow: none">
        <h4>Inspector 1, New booking</h4>
      </div>
    </div>
  </div>
  <form [formGroup]="bookingForm" (ngSubmit)="onSubmit()" class="ml-lg-5 mt-lg-5">
    <div class="row">
      <div class="col">
        <div class="form-group col-md-8">
          <label for="bookingNumber">Booking Number</label>
          <input type="text" class="form-control" id="bookingNumber" formControlName="bookingNumber" min="0"
            placeholder="Enter booking number" />
          <div *ngIf="
              (formSubmitted || bookingForm.controls.bookingNumber.touched) &&
              bookingForm.controls.bookingNumber.errors?.required
            " class="error">
            *Booking number is required!
          </div>
        </div>
        <div class="form-group col-md-8">
          <label for="selectContainer">Total Number Of Containers</label>
          <select class="form-control" id="selectContainer" formControlName="containers"
            (change)="onContainerChange($event)">
            <option value="0">Select containers</option>
          </select>
          <div *ngIf="
              (formSubmitted || bookingForm.controls.containers.touched) &&
              bookingForm.controls.containers.errors?.required
            " class="error">
            *Total number of containers is required!
          </div>
        </div>
        <div class="form-group col-md-8">
          <label for="inspectionDate">Date Of Inspection</label>
          <input type="date" class="form-control picker" id="inspectionDate" formControlName="inspectionDate"
            [min]="today" />
          <div *ngIf="
              (formSubmitted || bookingForm.controls.inspectionDate.touched) &&
              bookingForm.controls.inspectionDate.errors?.required
            " class="error">
            *Date of inspection Image is required!
          </div>
        </div>
      </div>

      <div class="col container-form" formArrayName="containerData">
        <ng-container *ngFor="let container of containerData.controls; let i = index">
          <div [formGroupName]="i">
            <label for="">Container {{ i + 1 }}</label>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="containerNumber" min="0"
                    placeholder="Enter container number" />
                  <div *ngIf="
                      (formSubmitted ||
                        container.get('containerNumber').touched) &&
                      container.get('containerNumber').errors?.required
                    " class="error">
                    *Container number is required!
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <input type="date" class="form-control picker" formControlName="inspectionDate" [min]="today" />
                  <div *ngIf="
                      (formSubmitted ||
                        container.get('inspectionDate').touched) &&
                      container.get('inspectionDate').errors?.required
                    " class="error">
                    *Date of inspection date is required!
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <button type="button" class="btn btn-primary rounded-circle" (click)="removeContainer(i)">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="form-group row mt-4">
      <div class="col-sm-10 text-center">
        <button type="submit" class="btn" style="
            background-color: #0065ff;
            width: 200px;
            border-radius: 10px;
            color: white;
          " [disabled]="!bookingForm.valid">
          Save
        </button>
      </div>
    </div>
  </form>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Saving...</p>
</ngx-spinner>