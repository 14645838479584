import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  apiPrefix: string;
  headers = new HttpHeaders();

  constructor(private http: HttpClient) {
    this.apiPrefix = environment.baseUrl + 'api/v1/notification/';
    this.headers = this.headers.set('x-access-token', localStorage.getItem("adventouresAdminAuthToken"));
  }

  sendNotificationsToAllUsers(params) {
    return this.http.post(this.apiPrefix + 'send-push', params, { headers: this.headers });
  }

}
