<div class="wrapper" style="margin-top: 0px" *ngIf="bookings">
  <div class="table2" style="margin-bottom: 10px">
    <div class="row2">
      <div class="cell2 d-flex text-left" style="box-shadow: none">
        <h4>{{ inspector.name }}</h4>
      </div>
      <div class="cell2"></div>
      <div class="cell2"></div>
      <div class="cell2 myCell pl-lg-2 mt-2">
        <button routerLink="/admin/bookings/create" style="background-color: #0065ff; border-radius: 8px; color: white"
          class="btn mb-1">
          <i class="fas fa-plus"></i> Add New Booking
        </button>
      </div>
    </div>
  </div>

  <table class="table text-center" style="box-shadow: none">
    <thead>
      <tr>
        <th scope="col">S.No</th>
        <th scope="col">Booking No.</th>
        <th scope="col">Total Container</th>
        <th scope="col">Date Of Inspection</th>
        <th scope="col">View All Container</th>
        <th scope="col">Share</th>
        <th scope="col">Document</th>
      </tr>
    </thead>
    <tbody style="color: gray; font-weight: 600" *ngIf="bookings?.length > 0">
      <tr *ngFor="
          let booking of bookings
            | paginate: { itemsPerPage: 10, currentPage: p };
          let i = index
        ">
        <th scope="row">{{ i + 1 }}</th>
        <td>{{ booking.bookingNumber }}</td>
        <td>{{ booking.containers }}</td>
        <td>{{ booking.inspectionDate | date: "mediumDate" }}</td>
        <td>
          <a routerLink="/admin/container" (click)="setBookingNumber(booking)">
            <i class="fas fa-external-link-alt" aria-hidden="true" style="font-size: 17px"></i>
          </a>
        </td>
        <td>
          <a href="javascript:void(0)" (click)="onShareClick(booking.bookingNumber)">
            <i class="fa fa-share" aria-hidden="true" style="font-size: 17px"></i>
          </a>
        </td>
        <td>
          <a href="javascript:void(0)" (click)="showDocumentUploadForm(booking)">
            <i class="fa fa-plus-circle" aria-hidden="true" style="font-size: 17px"></i>
          </a>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="bookings?.length == 0">
      <tr>
        <td colspan="6" align="center">
          <h5 style="color: #c2c2c2; margin: revert">No data to show</h5>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- pagination -->
  <pagination-controls (pageChange)="p = $event"></pagination-controls>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple"
  *ngIf="!isDocumentPopup">
  <p style="font-size: 20px; color: white">{{ loadingText }}</p>
</ngx-spinner>