import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContainerService } from 'src/app/services/container.service';

@Component({
  selector: 'app-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.css'],
})
export class SaveComponent implements OnInit {
  containerForm: FormGroup;
  formSubmitted = false;
  bookingNumber: number;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SaveComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private containerService: ContainerService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.containerForm = this.fb.group({
      containerNumber: ['', Validators.required],
      inspectionDate: ['', Validators.required],
    });
    this.bookingNumber = this.data.bookingNumber;
  }

  ngOnInit(): void {}

  onSubmit() {
    this.spinner.show();
    this.formSubmitted = true;
    if (this.containerForm.invalid) {
      this.spinner.hide();
      return;
    }

    this.containerForm.value.bookingNumber = this.bookingNumber;
    this.containerService.createContainer(this.containerForm.value).subscribe(
      (res: any) => {
        if (res.status) {
          this.spinner.hide();
          this.toast.success(res.message);
          this.closeModel();
        }
      },
      (error) => {}
    );
  }

  closeModel() {
    this.dialogRef.close();
  }
}
