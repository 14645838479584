<div class="container">
    <hr>
    <div class="row justify-content-center mb-4">
        <img [src]="customer.imageUrl" onerror="this.src='./../../../assets/profile.png'" height="72px" width="72px"
            alt="" style="border-radius: 50%;" />
    </div>
    <div class="row text-center my-2">
        <div class="col-md-6">
            <label for="">Name : </label>
        </div>
        <div class="col-md-6 my-2">
            {{ customer.name | titlecase }}
        </div>
    </div>
    <div class="row text-center my-2">
        <div class="col-md-6">
            <label for="">Email Id : </label>
        </div>
        <div class="col-md-6">
            {{ customer.user_email }}
        </div>
    </div>
    <div class="row text-center my-2">
        <div class="col-md-6">
            <label for="">Location : </label>
        </div>
        <div class="col-md-6">
            {{ customer.address }}
        </div>
    </div>
    <div class="row text-center my-2">
        <div class="col-md-4">
            <label for="">Bio : </label>
        </div>
    </div>
    <div class="row my-2">
        <div class="col-md-10 mx-auto border border-light-gray p-3 mb-5 bg-white rounded">{{ customer.bio }}</div>
    </div>
    <hr>
</div>