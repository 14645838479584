<div class="wrapper " style="margin-top: 0px;">
    <div class="table2 " style="margin-bottom: 10px;">
        <div class="row2">
            <div class="cell2 text-left" style="box-shadow: none;">
                <h4>{{ inspector.name }}</h4>
                <h4>Booking No: {{ bookingNumber }}</h4>
            </div>
            <div class="cell2"></div>
            <div class="cell2"></div>
            <div class="cell2 myCell pl-lg-2 mt-2 align-middle">
                <button (click)="createNewCategory()" style="background-color: #0065FF;border-radius: 8px;color: white;"
                    class="btn mb-1">
                    <i class="fas fa-plus"></i> Add New Container
                </button>
            </div>
        </div>
    </div>

    <table class="table text-center" style="box-shadow: none;">
        <thead>
            <tr>
                <th scope="col">S.No</th>
                <th scope="col">Container Numbar</th>
                <th scope="col">Date of Inspection</th>
                <th scope="col">Status</th>
                <th scope="col">View</th>
            </tr>
        </thead>
        <tbody style="color:gray;font-weight: 600;" *ngIf="containers?.length > 0">
            <tr *ngFor="let container of containers | paginate : { itemsPerPage:10, currentPage: p}; let i = index;">
                <th scope="row">{{i+1}}</th>
                <td>{{ container.containerNumber }}</td>
                <td>{{ container.inspectionDate | date:'mediumDate' }}</td>
                <td>{{ container.status }}</td>
                <td>
                    <a routerLink="/admin/container/view" (click)="setContainerId(container)">
                        <i class="fas fa-external-link-alt" aria-hidden="true" style="font-size: 17px;"></i>
                    </a>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="containers?.length == 0">
            <tr>
                <td colspan="6" align="center">
                    <h5 style="color: #c2c2c2; margin: revert;">No data to show</h5>
                </td>
            </tr>
        </tbody>
    </table>

    <!-- pagination -->
    <pagination-controls></pagination-controls> <!-- (pageChange)="p=$event" -->
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading container(s)...</p>
</ngx-spinner>