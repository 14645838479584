import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlogsService } from 'src/app/services/blogs.service';

@Component({
  selector: 'app-save-blog',
  templateUrl: './save-blog.component.html',
  styleUrls: ['./save-blog.component.css']
})
export class SaveBlogComponent implements OnInit, OnDestroy {

  editorText: string;
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],

        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link', 'image', 'video']
      ],
      handlers: { 'emoji': function () { } }
    },
  }

  blogForm: FormGroup;
  formSubmitted = false;

  constructor(
    private blogsSvc: BlogsService,
    private toast: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) {
    this.blogForm = this.fb.group({
      _id: 0,
      writtenBy: ['', Validators.required],
      title: ['', Validators.required],
      short_description: ['', Validators.required],
      content: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    let blogId = localStorage.getItem("blogId");
    if (blogId) {
      this.spinner.show();
      this.blogsSvc.getBlogList({}).subscribe((res: any) => {
        this.spinner.hide();
        let blog = {};
        blog = res.data.find((i: any) => i._id === blogId);
        this.blogForm.patchValue(blog);
        this.spinner.hide();
      });
    }
  }

  onSubmit() {
    this.spinner.show();
    this.formSubmitted = true;
    if (this.blogForm.invalid) {
      this.spinner.hide();
      return;
    }

    if (this.blogForm.value._id !== 0) {
      this.blogForm.value.id = this.blogForm.value._id;
      this.blogsSvc.updateBlog(this.blogForm.value).subscribe(
        (res: any) => {
          if (res.status) {
            this.spinner.hide();
            this.toast.success(res.message);
            this.router.navigate(['/admin/blogs'])
          }
        },
        (error) => { }
      );
    } else {
      this.blogsSvc.createBlog(this.blogForm.value).subscribe(
        (res: any) => {
          if (res.status) {
            this.spinner.hide();
            this.toast.success(res.description);
            this.router.navigate(['/admin/blogs'])
          }
        },
        (error) => { }
      );
    }
  }

  ngOnDestroy(): void {
    localStorage.removeItem("blogId");
  }
}
