import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';
import { InspectionService } from 'src/app/services/inspection.service';

@Component({
  selector: 'app-save-documents',
  templateUrl: './save-documents.component.html',
  styleUrls: ['./save-documents.component.css'],
})
export class SaveDocumentsComponent implements OnInit {

  booking: any;

  constructor(
    public dialogRef: MatDialogRef<SaveDocumentsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private inspectionService: InspectionService,
    private authService: AuthService
  ) {
    this.booking = this.data.booking;
  }

  ngOnInit(): void { }

  selectImage(e, type) {
    this.spinner.show();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('type', type);
    formData.append('bookingNumber', this.data.booking.bookingNumber);

    this.inspectionService.uploadInspectionDocument(formData).subscribe((res: any) => {
      let documentType = this.getDocumentType(type);
      this.readDocumentFile(res.file_path, documentType);
      this.toast.success(res.description, "Successs", {
        timeOut: 2500,
        progressBar: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-top-right'
      });
    }, (errors: any) => {
      this.spinner.hide();
      this.toast.success(errors.error.description, "Error Ocurred", {
        timeOut: 2500,
        progressBar: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-top-right'
      });
    });
  }

  closeModel() {
    this.dialogRef.close();
  }

  readDocumentFile(url, key) {
    this.authService.getFileBlob(url).subscribe(
      (res) => {
        this.spinner.hide();
        this.booking[key] = res;
      },
      (error) => { }
    );
  }

  downloadDocument(file, type) {
    if (file) {
      var fileURL: any = window.URL.createObjectURL(file);

      var a = document.createElement('a');
      a.href = fileURL;
      a.target = '_blank';
      a.download = type + '.' + file.type.substring(file.type.lastIndexOf('/') + 1);

      a.click();
    }
  }

  getDocumentType(type) {
    if (type === 'PACKAGING') {
      return 'packagingList';
    } else if (type === 'INVOICE') {
      return 'commercialInvoice';
    } else {
      return 'billOfLading';
    }
  }
}
