import { Component, OnInit } from '@angular/core';
import { data } from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { HomeContentService } from 'src/app/services/home-content.service';

@Component({
  selector: 'app-home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.css']
})
export class HomeContentComponent implements OnInit {

  editorText: string;
  contentData: any;
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link'],
        ['emoji'],
      ],
      handlers: { 'emoji': function () { } }
    },
  }

  constructor(
    private contentService: HomeContentService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getContent();
  }

  getContent() {
    this.contentService.getContent({ type: 'HOME' }).subscribe((res: any) => {
      if (res.status) {
        if (res.contentData) {
          this.contentData = res.contentData;
          this.editorText = this.contentData.description;
        }
      }
    }, (errors: any) => {
      this.spinner.hide();
      this.toast.error(errors.error.description, "Error Occured");
    });
  }

  onSubmit() {
    if (this.contentData == '') {
      this.contentData = {
        type: 'Home',
        content: this.editorText
      };
    } else {
      this.contentData.type = 'HOME';
      this.contentData.description = this.editorText;

      if (this.contentData._id) {
        this.contentData.id = this.contentData._id;
        this.contentService.updateContent(this.contentData).subscribe((res: any) => {
          if (res.status) {
            this.spinner.hide();
            this.toast.success("Content updated successfully", "Success");
          }
        }, (errors: any) => {
          this.spinner.hide();
          this.toast.error(errors.error.description, "Error Occured");
        });
      } else {
        this.contentService.createContent(this.contentData).subscribe((res: any) => {
          if (res.status) {
            this.spinner.hide();
            this.toast.success(res.description, "Success");
          }
        }, (errors: any) => {
          this.spinner.hide();
          this.toast.error(errors.error.description, "Error Occured");
        });
      }
    }
  }

}
