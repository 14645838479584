<div class="wrapper" style="margin-top: 0px">
  <div class="table2" style="margin-bottom: 10px">
    <div class="row2">
      <div class="cell2 text-left" style="box-shadow: none">
        <h4>Inspection Details</h4>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col mt-4">
      <form id="inspectionDetailsForm" [formGroup]="inspectionDetailsForm" (ngSubmit)="onSubmit()">
        <div class="form-group col-md-10">
          <label>Date Of Inspection</label>
          <input type="date" class="form-control" formControlName="inspectionDate" />
          <div
            *ngIf="(inspectionDetailsForm.controls.inspectionDate.touched) && inspectionDetailsForm.controls.inspectionDate.errors?.required "
            class="error">
            *Inspection date is required!
          </div>
        </div>
        <div class="form-group col-md-10">
          <label>Type Of Material to Load</label>
          <input type="text" class="form-control" formControlName="materialType" placeholder="Add name of product" />
          <div
            *ngIf="(inspectionDetailsForm.controls.materialType.touched) && inspectionDetailsForm.controls.materialType.errors?.required "
            class="error">
            *Material type required!
          </div>
        </div>
        <div class="form-group col-md-10">
          <label>Select Country Of Origin</label>
          <select class="form-control" formControlName="originCountry">
            <option value="" disabled selected>Select Country</option>
            <option *ngFor="let country of countries" [value]="country.name">{{country.name}}</option>
          </select>
          <div
            *ngIf="(inspectionDetailsForm.controls.originCountry.touched) && inspectionDetailsForm.controls.originCountry.errors?.required "
            class="error">
            *Country of origin is required!
          </div>
        </div>
        <div class="form-group col-md-10">
          <label>Loading Port</label>
          <input type="text" class="form-control" formControlName="loadingPort"
            placeholder="Add name of loading port" />
          <div
            *ngIf="(inspectionDetailsForm.controls.loadingPort.touched) && inspectionDetailsForm.controls.loadingPort.errors?.required "
            class="error">
            *Loading port is required!
          </div>
        </div>
        <div class="form-group col-md-10">
          <label>Destination Port</label>
          <input type="text" class="form-control" formControlName="destinationPort"
            placeholder="Type name of destination" />
        </div>
        <div class="form-group col-md-10">
          <label>Name Of Shipping Line</label>
          <input type="text" class="form-control" formControlName="shippingLineName"
            placeholder="Enter shipping line name" />
          <div
            *ngIf="(inspectionDetailsForm.controls.shippingLineName.touched) && inspectionDetailsForm.controls.shippingLineName.errors?.required "
            class="error">
            *Shipping line name is required!
          </div>
        </div>
        <div class="form-group col-md-10">
          <label>Shipping Line Seal</label>
          <input type="text" class="form-control" formControlName="shippingSeal" placeholder="Shipping line seal" />
          <div
            *ngIf="(inspectionDetailsForm.controls.shippingSeal.touched) && inspectionDetailsForm.controls.shippingSeal.errors?.required "
            class="error">
            *Shipping line seal is required!
          </div>
        </div>
        <div class="form-group col-md-10">
          <label>ATX Seal Number</label>
          <input type="text" class="form-control" formControlName="atxSeal" placeholder="Enter ATX seal number" />
          <div
            *ngIf="(inspectionDetailsForm.controls.atxSeal.touched) && inspectionDetailsForm.controls.atxSeal.errors?.required "
            class="error">
            *ATX seal number is required!
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-7 mt-4">
      <label class="mt-2">Details Photos</label><br />
      <div class="details-img" *ngIf="inspectionDetails?.detailedPhotos.length">
        <ng-container *ngFor="let photo of inspectionDetails.detailedPhotos">
          <ng-container>
            <img [src]="photo.full_path" onerror="this.src='./../../../../assets/personalityTest.jpg'" height="36px"
              width="36px" alt="" data-toggle="modal" data-target="#exampleModal" (click)="setImgPreview(photo)" />
          </ng-container>
        </ng-container>
      </div>
      <div class="pb-4 px-4 text-secondary" *ngIf="!inspectionDetails?.detailedPhotos.length">
        No image(s) to show
      </div>

      <label>Container Photos</label><br />
      <div class="details-img" *ngIf="inspectionDetails?.containerPhotos.length">
        <ng-container *ngFor="let photo of inspectionDetails.containerPhotos">
          <img [src]="photo.full_path" onerror="this.src='./../../../../assets/personalityTest.jpg'" height="36px"
            width="36px" alt="" data-toggle="modal" data-target="#exampleModal" (click)="setImgPreview(photo)" />
        </ng-container>
      </div>
      <div class="pb-4 px-4 text-secondary" *ngIf="!inspectionDetails?.containerPhotos.length">
        No image(s) to show
      </div>

      <label>Loaded Photos</label><br />
      <div class="details-img" *ngIf="inspectionDetails?.loadedPhotos.length">
        <ng-container *ngFor="let photo of inspectionDetails.loadedPhotos">
          <img [src]="photo.full_path" onerror="this.src='./../../../../assets/personalityTest.jpg'" height="36px"
            width="36px" alt="" data-toggle="modal" data-target="#exampleModal" (click)="setImgPreview(photo)" />
        </ng-container>
      </div>
      <div class="pb-4 px-4 text-secondary" *ngIf="!inspectionDetails?.loadedPhotos.length">
        No image(s) to show
      </div>

      <label>Door Photos</label><br />
      <div class="details-img" *ngIf="inspectionDetails?.doorPhotos.length">
        <ng-container *ngFor="let photo of inspectionDetails.doorPhotos">
          <img [src]="photo.full_path" onerror="this.src='./../../../../assets/personalityTest.jpg'" height="36px"
            width="36px" alt="" data-toggle="modal" data-target="#exampleModal" (click)="setImgPreview(photo)" />
        </ng-container>
      </div>
      <div class="pb-4 px-4 text-secondary" *ngIf="!inspectionDetails?.doorPhotos.length">
        No image(s) to show
      </div>

      <label>Shipping Line Photos</label><br />
      <div class="details-img" *ngIf="inspectionDetails?.shippingPhotos.length">
        <ng-container *ngFor="let photo of inspectionDetails.shippingPhotos">
          <img [src]="photo.full_path" onerror="this.src='./../../../../assets/personalityTest.jpg'" height="36px"
            width="36px" alt="" data-toggle="modal" data-target="#exampleModal" (click)="setImgPreview(photo)" />
        </ng-container>
      </div>
      <div class="pb-4 px-4 text-secondary" *ngIf="!inspectionDetails?.shippingPhotos.length">
        No image(s) to show
      </div>

      <label>Weight Ticket Photos</label><br />
      <div class="details-img" *ngIf="inspectionDetails?.weightTicketPhotos.length">
        <ng-container *ngFor="let photo of inspectionDetails.weightTicketPhotos">
          <img [src]="photo.full_path" onerror="this.src='./../../../../assets/personalityTest.jpg'" height="36px"
            width="36px" alt="" data-toggle="modal" data-target="#exampleModal" (click)="setImgPreview(photo)" />
        </ng-container>
      </div>
      <div class="pb-4 px-4 text-secondary" *ngIf="!inspectionDetails?.weightTicketPhotos.length">
        No image(s) to show
      </div>
      <a class="btn btn-primary mt-4" href="javascript:void(0)" role="button" (click)="getReport()"
        *ngIf="inspectionDetails?.documentPath"><i class="fa fa-download" aria-hidden="true"></i>&nbsp; Inspection
        Report</a>
    </div>
  </div>

  <div class="form-group row mt-5">
    <div class="col-sm-10 text-center">
      <button type="submit" class="btn" form="inspectionDetailsForm" [disabled]="!inspectionDetailsForm.valid" style="
          background-color: #0065ff;
          width: 200px;
          border-radius: 10px;
          color: white;
        ">
        Update
      </button>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 700px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Preview</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-auto">
        <img [src]="previewUrl" onerror="this.src='./../../../../assets/personalityTest.jpg'" alt="" width="600px"
          height="auto" />
      </div>
      <div class="modal-footer">
        <p class="ml-4">
          <span class="text-primary font-weight-bold">Address:
          </span>
          <span>{{previewAddress}}</span>
        </p>
      </div>
    </div>
  </div>
</div>