import { Component } from '@angular/core';
import * as $ from 'jquery';
import { AuthService } from './services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public toggleNavbar = true;
  public isLoggedIn = false;

  public firstName = '';
  public lastName = '';
  name: string;
  public email = '';

  adminId;
  userImage: any;
  currentPath: string;
  adminDetails: any;

  constructor(private authService: AuthService, private router: Router) {
    this.authService.listen().subscribe((m: any) => {
      this.ngOnInit();
    });
  }

  toggleFunc() {
    $("#menu-toggle").on('click', function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });

  }

  ngOnInit() {
    setTimeout(() => this.currentPath = this.router.url, 100)

    this.currentPath = this.router.url;
    this.authService.autoLogin();
    $("#menu-toggle").on('click', function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });

    this.authService.loggedIn.subscribe(res => {
      this.isLoggedIn = res;
      if (JSON.parse(localStorage.getItem('authAdventurousUserData')) != null) {
        this.adminId = JSON.parse(localStorage.getItem('authAdventurousUserData'))._id;
        this.email = JSON.parse(localStorage.getItem('authAdventurousUserData')).user_email;
        this.name = JSON.parse(localStorage.getItem('authAdventurousUserData')).name;
        this.adminDetails = JSON.parse(localStorage.getItem('authAdventurousUserData'));
        // this.firstName = JSON.parse(localStorage.getItem('authAdventurousUserData')).firstName;
        // this.lastName = JSON.parse(localStorage.getItem('authAdventurousUserData')).lastName;
      }
    })
  }

  logout() {
    this.authService.logout();
  }

  readFile(url) {
    this.authService.readFile(url).subscribe((image: any) => {
      this.userImage = image;
    }, error => { });
  }

}
